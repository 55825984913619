import axios from "axios";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import config from "../../../config";
import { ICategory, ISubCategory, ITransaction } from "../../../interfaces";
import { PayDiffKeys, TransactionMediums } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import { useTVA } from "../../../util/hooks/useTVA";

const { API_URL } = config[process.env.NODE_ENV];

const Encaissements = ({
  categs,
  transaction,
  callback,
  setRefreshCategs,
  diffPayCheckCallback,
}: {
  categs: ICategory[];
  transaction: ITransaction | null | undefined;
  callback: () => void;
    setRefreshCategs: (value: React.SetStateAction<boolean>) => void;
    diffPayCheckCallback: any;
}) => {
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const { setTVA } = useTVA();
  let api = useAxios();

  const toastMessage = (message: string, type: string) => {
    const options: any = {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    toast.dismiss();

    switch (type) {
      case "success":
        toast.success(message, options);
        break;

      case "warning":
        toast.warning(message, options);
        break;

      case "error":
        toast.error(message, options);
        break;

      default:
        break;
    }
  };

  const updateTransaction = async (categ: ISubCategory) => {
    try {
      toastMessage(
        "Cela peut prendre quelques secondes,s'il vous plaît, ne faites pas d'autre action ...",
        "warning"
      );
      let updatedTransaction = null as ITransaction | null | undefined;
      let payload: any = {
        id: transaction?.id,
        subCategoryId: categ.id,
        category: categ.key,
        status: transaction?.status === 101 ? 100 : transaction?.status,
      };

      if (!transaction?.factures || transaction?.factures.length === 0) {
        payload = {
          ...payload,
          vat:
            categ.TVA &&
            !isNaN(parseFloat(categ.TVA)) &&
            parseFloat(categ.TVA) !== 0
              ? setTVA(transaction?.amount!, parseFloat(categ.TVA) / 100)
              : null,
        };
      }

      const { data } = await api.post(`/api/Transaction/Update`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (
        data &&
        data.updated.length > 0 &&
        transaction &&
        data.updated[0].id === transaction.id
      ) {
        updatedTransaction = data.updated[0].view;
      }

      await callback();
      if (categ.key === PayDiffKeys.IN26 || categ.key === PayDiffKeys.IN27) {
        await diffPayCheckCallback(
          updatedTransaction && updatedTransaction.id
            ? updatedTransaction
            : transaction,
          categ
        );
        return;
      }
      toastMessage("Votre mise à jour a été effectuée avec succès", "success");
    } catch (error: any) {
      ErrorLogger("updating a transaction's catgeory", error);
      toastMessage(
        "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
        "error"
      );
    }
  };

  const payDiffFilter = (arr: ISubCategory[]) => {
    return !transaction?.diffPay_id
      ? arr
      : arr.filter((categ) => !["IN-26", "IN-27"].includes(categ.key));
  };

  return (
    <div className="list-encaissements-choix">
      {categs &&
        categs.length === 1 &&
        categs.map((elt) =>
          payDiffFilter(elt.sub_categories || [])
            ?.filter((elt) => {
              if (transaction?.medium === TransactionMediums.Manual) {
                return !["IN-017", "IN-016", "IN-019", "IN-022"].includes(
                  elt.key
                );
              } else {
                return elt;
              }
            })
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((elt, key) => (
              <div
                className="choix-item"
                key={key}
                onClick={async () => {
                  setRefreshCategs(true);
                  await updateTransaction(elt);
                }}
              >
                {elt.name}
              </div>
            ))
        )}
    </div>
  );
};

export default Encaissements;
