import React, {useEffect, useMemo, useState} from "react";
import "./Sidebar.scss";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {images} from "../../constants";
import {
  clientSidebarNav,
  backOfficeSidebarNav,
  SidebarNav,
} from "../../configs/sidebarNav";
import {ErrorLogger} from "../../util/errorLogger";
import config from "../../config";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {persistor} from "../../store";
import {Company, IPermission, User} from "../../interfaces";
import {usePermissions} from "../../util/hooks/usePermissions";
import LogOutIcon from "../../assets/LogOutIcon";
import {resetApplications} from "../../store/reducers/applications/applicationsSlice";
import {resetClientsList} from "../../store/reducers/clients-list/clientsSlice";
import {resetPermissionsList} from "../../store/reducers/permissions-list/permissionsListSlice";
import {resetUser} from "../../store/reducers/user/userSlice";
import {resetCategories} from "../../store/reducers/categories/categoriesSlice";
import {resetCompaniesList} from "../../store/reducers/companies-list/companiesSlice";
import {ReactSVG} from "react-svg";
import IconLogOut from "../../assets/images/svg/icon-nav-5.svg";
import useAxios from "../../util/hooks/useAxios";
import {UserTypes} from "../../util/context";
import {resetNotifs} from "../../store/reducers/messages-notifs/messagesNotifsSlice";
import {
  resetUtil,
  setReloadTickets,
} from "../../store/reducers/utils/utilSlice";
import {toast} from "react-toastify";
import {deleteWhere} from "../../util/helpers/utilities";
import {resetRoot} from "../../store/reducers/root/rootSlice";
import Select from "react-select";

const Sidebar: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const [sideBarNav, setSideBarNav] = useState<SidebarNav[]>([]);
  const [loading, setLoading] = useState(false);

  const {user, permissions, company, userCompanies} = useSelector(
    (state: {root: object; user: object}) => state.user
  ) as {
    user: User;
    permissions: IPermission[];
    company: Company;
    userCompanies: Company[];
  };

  let [leftSidebarOpen, setleftSidebarOpen] = useState(false);

  function openSidebar() {
    setleftSidebarOpen(true);
  }
  function toogleSidebar() {
    setleftSidebarOpen(!leftSidebarOpen);
  }
  function closeSidebar() {
    setleftSidebarOpen(!leftSidebarOpen);
  }
  function getCollapseClass() {
    return leftSidebarOpen ? "" : "collapsed";
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    pack: string;
  };

  let api = useAxios();

  const leave = async () => {
    try {
      if (creds.role === UserTypes.Client) {
        await deleteWhere(api, creds.token, "CountNotif", {
          company_id: creds.company_id,
        });
      }
      await api.get(`/api/auth/logout`, {
        headers: {
          "x-access-token": creds.token,
        },
      });
      persistor.purge();
      dispatch(resetRoot());
      dispatch(resetApplications());
      dispatch(resetCategories());
      dispatch(resetClientsList());
      dispatch(resetCompaniesList());
      dispatch(resetPermissionsList());
      dispatch(resetUser());
      dispatch(resetNotifs());
      dispatch(resetUtil());
      dispatch(setReloadTickets(false));
      toast.dismiss();
      navigate("/login");
    } catch (error: any) {
      ErrorLogger("logout", error);
    }
  };

  const {hasAccessPermission} = usePermissions();
  console.log("userCompanies", userCompanies);

  const companiesSelectFormatter = useMemo(
    () =>
      userCompanies[0]
        ? [
            ...userCompanies.map((elt) => ({
              label: elt.name || elt.email,
              value: elt.id,
            })),
            // {
            //   label: "Nouvelle entreprise",
            //   value: "0000",
            // },
          ]
        : [],
    [userCompanies]
  );

  // let refreshSelectedCompany = (newCompany: {label: string; value: string}) => {
  //   if (newCompany.value === "0000") {
  //     navigate("/register?from=existing_user");
  //     return;
  //   }
  //   if (newCompany.value === company.id) {
  //     return;
  //   }
  //   let newCompanyInfo = userCompanies.find(
  //     (elt) => elt.id === newCompany.value
  //   );
  //   if (newCompanyInfo) {
  //     dispatch(setCompanyId(newCompanyInfo.id));
  //     dispatch(setCompanyPack(newCompanyInfo.pack));
  //     dispatch(setCompany(newCompanyInfo));
  //   }
  // };

  return (
    <>
      <div className="sidebar__logo-smart">
        <img src={images.logo} alt="" />
        {/* {<ReactSVG src={images.logo} />} */}
      </div>
      <div className="sidebar-toggle" onClick={toogleSidebar}>
        <i className="bx bx-menu-alt-left"></i>
      </div>
      <div className={`sidebar ${getCollapseClass()}`}>
        <div className="sidebar__logo">
          <img src={images.logo} alt="" />
          {/* {<ReactSVG src={images.logo} />} */}
        </div>

        <div className="sidebar__menu">
          <div className="sidebar-close" onClick={closeSidebar}>
            <i className="bx bx-x"></i>
          </div>
          <ul className="sideBarNav">
            {creds.role === UserTypes.Client && (
              <li>
                <Select
                  options={companiesSelectFormatter}
                  closeMenuOnSelect={true}
                  placeholder="Mes entreprises"
                  classNamePrefix="select"
                  className="custom-select form-secondary"
                  // onChange={(newCompany) => {
                  //   refreshSelectedCompany(newCompany!);
                  // }}
                  value={{
                    label: companiesSelectFormatter[0]?.label ?? "",
                    value: companiesSelectFormatter[0]?.value ?? "",
                  }}
                />
              </li>
            )}
            {(creds.role === UserTypes.Client
              ? clientSidebarNav
              : backOfficeSidebarNav
            )
              .filter((nav) => {
                if (creds.role === UserTypes.Cabinet) {
                  const hasAccess = hasAccessPermission(
                    permissions,
                    nav.whiteList!
                  );
                  if (nav.link === "/") {
                    return nav;
                  } else {
                    if (hasAccess) {
                      return nav;
                    } else {
                      return;
                    }
                  }
                }
                if (creds.role === UserTypes.Client) {
                  if (nav.link.includes("bilan")) {
                    if (company.id && company.Bilan) {
                      return nav;
                    }
                  } else {
                    if (!nav.blackList?.includes(creds.pack)) {
                      return nav;
                    }
                  }
                } else {
                  return nav;
                }
              })
              .map((nav, index) => {
                return (
                  <li
                    key={`nav-${index}`}
                    className={`menu-item ${
                      location.pathname === nav.link ? "active" : ""
                    }`}
                    title={nav.text}
                  >
                    <Link
                      to={nav.link}
                      className={`sidebar__menu__item icon-${index}`}
                    >
                      <div
                        className={`sidebar__menu__item__icon icon-${index} ${
                          user &&
                          user.id &&
                          creds.role === UserTypes.Client &&
                          `client_icon-${index}`
                        }`}
                      >
                        <img src={nav.icon} alt="icon" />
                      </div>
                      <div className="sidebar__menu__item__txt">{nav.text}</div>
                    </Link>
                  </li>
                );
              })}
          </ul>
          <div className="sideBar-logout">
            <div className="sidebar__menu__item" onClick={() => leave()}>
              <div className="sidebar__menu__item__icon">
                <img src={IconLogOut} alt="icon" />
              </div>
              <div className="sidebar__menu__item__txt">Déconnexion</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
