import React, {useEffect, useState} from "react";
import "./TransactionsArchive.scss";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import {BootyPagination} from "../../../components/table/pagination";
import {data} from "../../../constants";
import {BsTrashFill} from "react-icons/bs";
import {Company, IS3, ITransaction} from "../../../interfaces";
import {ErrorLogger} from "../../../util/errorLogger";
import {useDispatch, useSelector} from "react-redux";
import config from "../../../config";
import axios from "axios";
import moment from "moment";
import {
  setCounter,
  setErrorMessage,
} from "../../../store/reducers/utils/utilSlice";
import UserOrangeIcon from "../../../assets/UserOrangeIcon";
import {FaEye, FaFileInvoice} from "react-icons/fa";
import {useFormatter} from "../../../util/hooks/useFormatter";
import FileViewer from "../../../components/FileViewer";
import ArchiveIcon from "../../../assets/ArchiveIcon";
import {AppPacks, PayDiffKeys, UserTypes} from "../../../util/context";
import useAxios from "../../../util/hooks/useAxios";
import DeArchiveIcon from "../../../assets/DeArchiveIcon";
moment.updateLocale("fr", {});

export interface AvaliderProps {}

const {API_URL} = config[process.env.NODE_ENV];

const TransactionsArchive = ({
  context,
  company,
  reload,
  transactionNameSearch,
  setTransactionNameSearch,
}: {
  context?: string;
  company?: Company;
  reload?: boolean;
  transactionNameSearch?: string;
  setTransactionNameSearch?: any;
}) => {
  const [open, setOpen] = useState(false);
  const [transactionId, setTransactionId] = useState<string | null>();
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [documentsModal, setDocumentsModal] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<ITransaction | null>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const ontoggle = (id: string) => {
    setTransactionId(id);
    setOpen(true);
  };

  const [pending, setPending] = useState<boolean>(true);

  const {setDecimalDigits} = useFormatter();
  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {user_id: string; company_id: string; token: string; role: string};
  let api = useAxios();

  const columns = React.useMemo(
    () => [
      {
        name: "",
        selector: (row: any) => row.iban,
        cell: (row: ITransaction) => (
          <span className="code-cell">
            {row.iban && row.iban.code && <span>{row.iban.code}</span>}
            {row.type && row.type === "cash" && <span>CA</span>}
            {row.type && row.type === "expense" && <span>NDF</span>}
          </span>
        ),
        id: "code-cell-wrapper",
      },
      {
        name: "Date",
        selector: (row: any) => row.date,
        sortable: true,
        cell: (row: {date: Date}) => moment(row.date).format("DD/MM/YYYY"),
      },
      {
        name: "Transaction",
        cell: (row: any) => (
          <div className="tr-categ-cell-wrapper">
            <p>{row.label}</p>
          </div>
        ),
        sortable: true,
      },
      {
        name: "Catégorie",
        selector: (row: any) => (row.sub_category ? row.sub_category.name : ""),
        sortable: true,
        cell: (row: any) => (
          <div className="file-table">
            <div className="list-Files transaction-file">
              <span className="file-box-item category">
                {row.sub_category ? row.sub_category.name : ""}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Justificatifs",
        selector: (row: any) => row.justif,
        sortable: true,
        cell: (row: ITransaction) => (
          <>
            <div className="file-table">
              <div className="list-Files transaction-file archived-transaction-file">
                {row.documents && row.documents.length > 0 && (
                  <span
                    className="file-box-item justif-viewer"
                    onClick={() => {
                      setTransaction(row);
                      setDocumentsModal(true);
                    }}
                  >
                    <FaEye
                      size={20}
                      style={
                        row.factures && row.factures.length > 0
                          ? {margin: "0px"}
                          : {}
                      }
                    />{" "}
                    Voir tous{" "}
                    {row.factures && row.factures.length > 0 && (
                      <>
                        {" "}
                        <FaFileInvoice
                          size={15}
                          style={{margin: "0px"}}
                        ></FaFileInvoice>
                      </>
                    )}
                  </span>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        name: "Montant",
        selector: (row: any) => row.amount,
        sortable: true,
        cell: (row: any) => (
          <span
            className={parseFloat(row.amount) > 0 ? "green-num" : "red-num"}
          >
            {setDecimalDigits(row.amount)} €
          </span>
        ),
      },
      {
        name: "Tva",
        omit: !company?.subjectToVAT,
        cell: (row: ITransaction) => {
          let vatPercentage =
            row.vatPercentage !== null && row.vatPercentage !== undefined
              ? `${row.vatPercentage} %`
              : row.sub_category &&
                row.sub_category.TVA &&
                !isNaN(parseFloat(row.sub_category.TVA))
              ? `${parseFloat(row.sub_category.TVA)} %`
              : (null as any);

          if (row.splittedTransactions && row.splittedTransactions.length > 0) {
            vatPercentage = "Mixte";
          }
          return (
            <div className="tva-box">
              <div className="tva-box-display  i-secondary">
                <span>
                  {row.vat ? `${parseFloat(row.vat).toFixed(2)} € TVA` : ""}
                </span>
              </div>
              {vatPercentage && (
                <span className="mixte-ind">Taux : {vatPercentage}</span>
              )}
            </div>
          );
        },
        sortable: true,
      },
      {
        name:
          creds.role === UserTypes.Client
            ? company?.pack !== AppPacks.Entrepreneur
              ? "Désarchiver"
              : "Décomptabiliser"
            : "Action",
        button: true,
        omit: creds.role === UserTypes.Client,
        cell: (row: ITransaction) => (
          <>
            <button
              className="btn btn-orange"
              onClick={async () => await deArchiveTransaction(row)}
            >
              <DeArchiveIcon />
            </button>
          </>
        ),
      },
    ],
    [company, creds]
  );

  const documentsColumns = [
    {
      name: "Document",
      selector: (row: any) => row.key,
      sortable: true,
    },
  ];

  const [transactionsToValid, setTransactionsToValid] = useState<
    ITransaction[]
  >([]);

  const deArchiveTransaction = async (row: ITransaction) => {
    try {
      await api.post(
        `/api/Transaction/Update`,
        {
          id: row.id,
          status: 101,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setTransactionsToValid((prevState) =>
        prevState.filter((trans) => trans.id !== row.id)
      );

      if (
        row.sub_category.key === PayDiffKeys.OUT101 ||
        row.sub_category.key === PayDiffKeys.OUT100
      ) {
        await api.post(
          `/api/Transaction/UpdateWhere`,
          {
            where: {
              diffPay_id: row.id,
            },
            data: {
              status: 101,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }
    } catch (error: any) {
      ErrorLogger("de-archiving a transaction", error);
    }
  };

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getTransactions = async (nameSearch: string = "") => {
    if (!context) {
      return;
    }
    try {
      let where: any = {
        status: company && company.pack === AppPacks.Entrepreneur ? 104 : 102,
        companyId: context,
        diffPay_id: {
          isNull: true,
        },
        relatedToFactureType: {
          or: [null, "child"],
        },
      };
      if (nameSearch && nameSearch !== "") {
        where = {
          ...where,
          label: {
            iLike: `%${nameSearch}%`,
          },
        };
      }
      const {data} = await api.post(
        `/api/Transaction/all`,
        {
          where,
          perPage: 20,
          pageIndex: currentPage,
          order: [
            ["date", "DESC"],
            ["id", "DESC"],
          ],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const {data: transactionsData} = data;

      setTransactionsToValid(
        transactionsData.sort((a: {date: number}, b: {date: number}) => {
          return moment(b.date).diff(moment(a.date));
        })
      );
      setTotalRows(data.count);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting non valid transactions", error);
    }
  };

  useEffect(() => {
    if (transactionNameSearch) {
      getTransactions(transactionNameSearch);
    } else {
      getTransactions();
    }
  }, [currentPage]);

  useEffect(() => {
    if (reload) {
      setPending(true);
      handlePageChange(1);
      if (transactionNameSearch && transactionNameSearch !== "") {
        setTransactionNameSearch("");
      }
      getTransactions();
      setPending(false);
    }
  }, [reload]);

  useEffect(() => {
    if (transactionNameSearch) {
      getTransactions(transactionNameSearch);
    }
  }, [transactionNameSearch]);

  return (
    <>
      <DataTable
        columns={columns}
        data={transactionsToValid}
        noDataComponent={<p>Il n'y a aucun data à afficher</p>}
        pagination
        paginationComponent={(props) => {
          const customProps = {...props, color: "primary"};
          return <BootyPagination {...customProps} />;
        }}
        paginationServer
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        paginationPerPage={20}
        progressPending={pending}
        paginationDefaultPage={currentPage}
        progressComponent={
          <>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
          </>
        }
      />
      <div className="openbtn text-center">
        {/*
         transaction documents
        */}
        <Modal
          className="modal-primary modal-dialog-centered "
          isOpen={documentsModal}
          toggle={() => {
            setDocumentsModal(false);
            setTransaction(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setDocumentsModal(false);
              setTransaction(null);
            }}
          >
            Justificatifs de la transaction {transaction?.label}
          </ModalHeader>

          <ModalBody>
            <div className="content-form-block">
              <div className="text-center">
                Pour prévisualiser un document, il suffit de cliquer dessus.
              </div>
              <DataTable
                columns={documentsColumns}
                data={transaction?.documents!}
                noDataComponent={<p>Il n'y a aucun data à afficher</p>}
                paginationComponent={(props) => {
                  const customProps = {...props, color: "primary"};
                  return <BootyPagination {...customProps} />;
                }}
                onRowClicked={(row: IS3, e: any) => {
                  setUrl(row.url);
                  setViewModal(true);
                }}
              />
            </div>
          </ModalBody>
        </Modal>
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
};

export default TransactionsArchive;
