import React, {
  Dispatch,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Spinner,
  Row,
  Col,
  Label,
  ModalFooter,
} from "reactstrap";
import {
  IBridgeAccount,
  IBridgeItem,
  IRelatedAccount,
  User,
} from "../../../../interfaces";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {OptionType, UserTypes, toastSettings} from "../../../../util/context";
import Select from "react-select";
import CalenderIconBlue from "../../../../assets/images/svg/calender-icon-blue.svg";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
import "moment/locale/fr";
import DataTable from "react-data-table-component";
import {BsTrashFill} from "react-icons/bs";
import {
  AiFillCheckCircle,
  AiFillTool,
  AiFillWarning,
  AiOutlineBank,
  AiOutlineCheck,
  AiOutlineCloudSync,
} from "react-icons/ai";
import {ToastOptions, toast} from "react-toastify";
import {useSelector} from "react-redux";
import useAxios from "../../../../util/hooks/useAxios";
import {ErrorLogger} from "../../../../util/errorLogger";
import "./SynchroniseModal.scss";
import {deleteBridgeItem, fixBridgeItem} from "../../../../api/bridgeItem";
import {BootyPagination} from "../../../../components/table/pagination";
import {getStatusDescription} from "../../../../util/function";
import CompteGestionIbanModal from "../CompteGestionIbanModal/CompteGestionIbanModal";
import {FaSync} from "react-icons/fa";
import {RiRefreshFill} from "react-icons/ri";
import {useParams} from "react-router";
registerLocale("fr", fr);

type Props = {
  bridegItems: IBridgeItem[];
  setGestionModal: Dispatch<React.SetStateAction<boolean>>;
  gestionModal: boolean;
  getBridgeAcoounts: () => Promise<void>;
  setStopSyncModal: Dispatch<React.SetStateAction<boolean>>;
  creds: {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    application_id: string;
  };
  totalRows: number;
  handlePageChange: any;
  loading: boolean;
  currentPage: number;
  clientId: string;
};

const GestionCompte = ({
  bridegItems,
  setGestionModal,
  gestionModal,
  getBridgeAcoounts,
  creds,
  setStopSyncModal,
  totalRows,
  handlePageChange,
  loading,
  clientId,
}: Props) => {
  const [loadingState, setLoadingState] = useState({
    id: 0,
    status: false,
    role: "",
  });
  const [bridgeItem, setBridegItem] = useState<IBridgeItem | null>(null);
  const [openIbanModal, setOpenIbanModal] = useState(false);
  const [ibans, setIbans] = useState<IBridgeAccount[]>([]);

  let api = useAxios();

  const {id} = useParams();

  const {
    control: syncControl,
    handleSubmit: syncHandleSubmit,
    reset: resetForm,
  } = useForm<{
    since: Date;
    account: Array<OptionType & {iban: string}>;
    until: Date;
  }>({
    defaultValues: {
      since: undefined,
      until: undefined,
      account: [],
    },
  });

  //function for gestion ibans
  const deleteItem = useCallback(
    async ({itemId, companyId}: {itemId: number; companyId: string}) => {
      setLoadingState({
        id: itemId,
        status: true,
        role: "delete",
      });
      try {
        await deleteBridgeItem({
          api,
          itemId: Number(itemId),
          clientId,
        });
        toast.dismiss();
        toast.success("votre opération est réussie", toastSettings);
        getBridgeAcoounts();
      } catch (error: any) {
        toast.dismiss();
        toast.error("votre opération a échoué", toastSettings);
      }
      setLoadingState({
        id: 0,
        status: false,
        role: "",
      });
    },
    []
  );

  const fixItem = async (row: IBridgeItem) => {
    toast.dismiss();
    toast.info("Votre operation est en progress", toastSettings);
    setLoadingState({
      id: row.id,
      status: true,
      role: "update",
    });
    console.log("testtttt", {
      itemId: row.id,
      clientId,
      status: row.status,
      from: creds.application_id,
      api,
    });
    try {
      const resp = await fixBridgeItem({
        itemId: row.id,
        clientId,
        status: row.status,
        from: row.company.applicationId,
        api,
      });

      if (!!resp?.data?.url) {
        window.location = resp?.data.url;
      }

      await getBridgeAcoounts();
    } catch (error) {
      toast.dismiss();
      toast.error("votre opération a échoué", toastSettings);
    } finally {
      setLoadingState({
        id: 0,
        status: false,
        role: "",
      });
    }
  };

  // const refreshItem = async (item: IBridgeItem) => {
  //   toast.dismiss();
  //   toast.info("Votre operation est en progress", toastSettings);
  //   setLoadingState({
  //     id: item.id,
  //     status: true,
  //     role: "update",
  //   });
  //   try {
  //     await api.post(
  //       `/api/accountSync/refreshItem`,
  //       {
  //         companyId: creds.role === UserTypes.Client ? creds.company_id : id,
  //         itemId: item.id,
  //       },
  //       {
  //         headers: {
  //           "x-access-token": creds.token,
  //         },
  //       }
  //     );

  //     getBridgeAcoounts();
  //     setTimeout(() => {
  //       setLoadingState({
  //         id: 0,
  //         status: false,
  //         role: "",
  //       });
  //       toast.dismiss();
  //       toast.success("votre opération est réussie", toastSettings);
  //     }, 5000);
  //   } catch (error) {
  //     toast.dismiss();
  //     toast.error("votre opération a échoué", toastSettings);
  //   }
  // };

  // function for gestion iban modal
  const handelIbanModal = useCallback(() => {
    return setOpenIbanModal((prevValue) => {
      return !prevValue;
    });
  }, []);

  const openGestionIbanModal = useCallback((row: IBridgeItem) => {
    // setIbans
    setIbans(
      row.bridge_accounts.map((elt) => ({
        ...elt,
        iban: elt.iban || "",
      }))
    );
    handelIbanModal();
  }, []);

  // const multipleSync: SubmitHandler<{
  //   since: Date;
  //   account: Array<OptionType & {iban: string}>;
  //   until: Date;
  // }> = async ({since, until, account}) => {
  //   setStopSyncModal(false);
  //   setLoadingState({
  //     status: true,
  //     role: "",
  //     id: 0,
  //   });

  //   if (!since) {
  //     toast.dismiss();
  //     toast.error("Vous devez choisir des dates valides!", toastSettings);
  //     setLoadingState({
  //       status: false,
  //       role: "",
  //       id: 0,
  //     });
  //     return;
  //   }

  //   if (account.length === 0) {
  //     toast.dismiss();
  //     toast.error("Veuillez choisir au moins un compte", toastSettings);
  //     setLoadingState({
  //       status: false,
  //       role: "",
  //       id: 0,
  //     });
  //     return;
  //   }
  //   if (
  //     account.some((act) => {
  //       return !act.iban;
  //     })
  //   ) {
  //     toast.dismiss();
  //     toast.error(
  //       "Veuillez choisir des comptes avec iban ou vous pouvez mettre à jour avant d'ajouter le compte",
  //       toastSettings
  //     );
  //     setLoadingState({
  //       status: false,
  //       role: "",
  //       id: 0,
  //     });
  //     return;
  //   }

  //   try {
  //     setLoadingState((prevValue) => ({
  //       ...prevValue,
  //       status: true,
  //     }));
  //     const companyId = bridgeItem?.company_id;
  //     const accountList = account.map((item) => {
  //       return {id: item.value, iban: item.iban};
  //     });

  //     const {data} = await api.post(
  //       `/api/accountSync/syncTransactions`,
  //       {
  //         accounts: accountList,
  //         sinceUntil: {
  //           since: moment(since as unknown as string).format("YYYY-MM-DD"),
  //           until: moment(until || since).format("YYYY-MM-DD"),
  //         },
  //         companyId: companyId,
  //       },
  //       {
  //         headers: {
  //           "x-access-token": creds.token,
  //         },
  //       }
  //     );

  //     if (data.error) {
  //       setLoadingState({
  //         id: 0,
  //         status: false,
  //         role: "",
  //       });
  //       toast.dismiss();
  //       toast.error(
  //         "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard",
  //         toastSettings
  //       );
  //       return;
  //     }
  //     setLoadingState({
  //       id: 0,
  //       status: false,
  //       role: "",
  //     });
  //     if (data.insert && data.insert === 1) {
  //       toast.dismiss();
  //       toast.success(
  //         "Vos comptes sont synchronisés avec succès.",
  //         toastSettings
  //       );
  //       cancelEditDate();
  //     }
  //   } catch (error: any) {
  //     ErrorLogger("creating sync org", error);
  //     setLoadingState({
  //       id: 0,
  //       status: false,
  //       role: "",
  //     });
  //     setStopSyncModal(false);
  //     toast.dismiss();
  //     toast.error(
  //       "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.",
  //       toastSettings
  //     );
  //   }
  // };

  const cancelEditDate = () => {
    resetForm();
    setGestionModal(false);
  };

  const columns = [
    {
      name: "Banque",
      sortable: true,
      width: "20%",
      cell: (row: any) => (
        <div
          style={{
            display: "flex",
            gap: "2px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.bankInfo && row.bankInfo.logo_url && (
            <img src={row.bankInfo.logo_url} className="bankImg" />
          )}
          {row.bankInfo && row.bankInfo.name && (
            <p style={{margin: 0, minWidth: "30px"}}>{row.bankInfo.name}</p>
          )}
        </div>
      ),
    },
    {
      name: "Status",
      width: "20%",
      cell: (row: IBridgeItem) => (
        <div
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.status === 0 ? (
            <AiFillCheckCircle color="green" width={"25px"} height={"25px"} />
          ) : (
            <AiFillWarning
              color="orange"
              style={{width: "25px", height: "25px"}}
            />
          )}
        </div>
      ),
    },
    {
      name: "Description",
      selector: (row: IBridgeItem) => row.status,
      width: "30%",
      cell: (row: IBridgeItem) => (
        <div
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.status === 0
            ? "Valider"
            : getStatusDescription(row.status.toString())}
        </div>
      ),
    },
    {
      name: "Actions",
      width: "30%",
      cell: (row: IBridgeItem) => (
        <div className="flex-wrap" style={{gap: "2vw"}}>
          {/* <>
              <button
                className="btn btn-blue"
                type="submit"
                disabled={loadingState.status}
                onClick={() => handelModal(row)}
                title="Synchroniser votre compte"
              >
                <FaSync />
              </button>
              <button
                className="btn btn-orange"
                type="submit"
                disabled={loadingState.status}
                onClick={async () => await refreshItem(row)}
                title="Actualiser votre compte"
              >
                <AiOutlineCloudSync />
              </button>
            </> */}

          <button
            className="btn btn-blue"
            onClick={() => fixItem(row)}
            disabled={loadingState.status || row.status === 0}
          >
            {loadingState.id === row.id && loadingState.role === "update" ? (
              <Spinner color="light" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              <AiFillTool />
            )}
          </button>

          {/* <button
            className="btn btn-green"
            title="Gestion iban"
            disabled={loadingState.status}
            onClick={() => openGestionIbanModal(row)}
          >
            <AiOutlineBank />
          </button> */}
          <button
            className="btn btn-red"
            onClick={() =>
              deleteItem({
                companyId: row.companyId,
                itemId: row.id,
              })
            }
            title="Supprimer le statut de devis"
            disabled={loadingState.status}
          >
            {loadingState.status &&
            loadingState.id === row.id &&
            loadingState.role === "delete" ? (
              <Spinner color="red" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              <BsTrashFill />
            )}
          </button>
        </div>
      ),
    },
  ];

  return (
    <div style={{position: "relative", marginBottom: "80px"}}>
      <CompteGestionIbanModal
        openIbanModal={openIbanModal}
        handelModal={handelIbanModal}
        ibans={ibans}
        getBridgeAccounts={getBridgeAcoounts}
        clientId={clientId}
      />

      <DataTable
        columns={columns}
        data={bridegItems}
        noDataComponent={<p>Il n'y a aucun data à afficher</p>}
        pagination
        progressComponent={
          <>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
          </>
        }
        paginationServer
        paginationComponent={(props) => {
          const customProps = {...props, color: "secondary"};
          return <BootyPagination {...customProps} />;
        }}
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        paginationPerPage={5}
        progressPending={loading}
      />
    </div>
  );
};

export default GestionCompte;
