import React, {useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {toastSettings} from "../../../util/context";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import DatePicker, {registerLocale} from "react-datepicker";
import fr from "date-fns/locale/fr";

import moment from "moment";
import "moment/locale/fr";
import {toast} from "react-toastify";
import useAxios from "../../../util/hooks/useAxios";
import {ErrorLogger} from "../../../util/errorLogger";
registerLocale("fr", fr);

type Props = {
  gestionModal: boolean;
  setGestionModal: React.Dispatch<React.SetStateAction<boolean>>;
  getBridgeAccounts: () => Promise<void>;
  bridgeAccountId: string | number;
  clientId: string;
  companyId: string;
  ibanId: string;
};

const SynchroniseBridgeAccountModal = ({
  gestionModal,
  setGestionModal,
  bridgeAccountId,
  clientId,
  companyId,
  ibanId,
}: // creds,
Props) => {
  const [loadingState, setLoadingState] = useState({
    id: 0,
    status: false,
    role: "",
  });
  const {
    control: syncControl,
    handleSubmit: syncHandleSubmit,
    reset: resetForm,
    watch,
  } = useForm<{
    since: Date;
    until: Date;
  }>({
    defaultValues: {
      since: undefined,
      until: undefined,
    },
  });

  const api = useAxios();

  const cancelEditDate = () => {
    resetForm();
    console.log("reset");
    setGestionModal(false);
    setLoadingState({
      id: 0,
      role: "",
      status: false,
    });
  };

  const multipleSync: SubmitHandler<{
    since: Date;

    until: Date;
  }> = async ({since, until}) => {
    setLoadingState({
      status: true,
      role: "",
      id: 0,
    });

    if (!since) {
      toast.dismiss();
      toast.error("Vous devez choisir des dates valides!", toastSettings);
      setLoadingState({
        status: false,
        role: "",
        id: 0,
      });
      return;
    }
    if (!companyId) {
      toast.dismiss();
      toast.error(
        "Vous devez choisir une entreprise valide et le sauvegarder puis vous pourrez faire la synchronisation!",
        toastSettings
      );
      setLoadingState({
        status: false,
        role: "",
        id: 0,
      });
      return;
    }

    if (!ibanId) {
      toast.dismiss();
      toast.error(
        "Vous devez ajouter un Iban validé et le sauvegarder puis vous pourrez faire la synchronisation!",
        toastSettings
      );
      setLoadingState({
        status: false,
        role: "",
        id: 0,
      });
      return;
    }

    try {
      setLoadingState((prevValue) => ({
        ...prevValue,
        status: true,
      }));

      const {data} = await api.post(
        `/api/v2/accountSync/syncTransactions`,
        {
          bridgeAccountId: bridgeAccountId,
          clientId: clientId,
          sinceUntil: {
            since: moment(since as unknown as string).format("YYYY-MM-DD"),
            until: moment(until || new Date()).format("YYYY-MM-DD"),
          },
          companyId: companyId,
          ibanId: ibanId,
        },
        {
          headers: {
            "x-access-token": "creds.token",
          },
        }
      );

      if (data.error) {
        setLoadingState({
          id: 0,
          status: false,
          role: "",
        });
        toast.dismiss();
        toast.error(
          "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard",
          toastSettings
        );
        return;
      }
      setLoadingState({
        id: 0,
        status: false,
        role: "",
      });
      if (data.insert && data.insert === 1) {
        toast.dismiss();
        toast.success(
          "Vos comptes sont synchronisés avec succès.",
          toastSettings
        );
      } else {
        toast.success(
          "Il n'y a aucun changement dans vos comptes",
          toastSettings
        );
      }
      cancelEditDate();
    } catch (error: any) {
      ErrorLogger("creating sync org", error);
      setLoadingState({
        id: 0,
        status: false,
        role: "",
      });
      toast.dismiss();
      toast.error(
        "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.",
        toastSettings
      );
    }
  };

  return (
    <Modal
      className="modal-secondary modal-dialog-centered"
      isOpen={gestionModal}
      toggle={cancelEditDate}
    >
      <ModalHeader toggle={cancelEditDate}>
        Confirmer la synchronisation
      </ModalHeader>
      <ModalBody>
        <div className="acoounts-choice">
          <form
            onSubmit={syncHandleSubmit(multipleSync)}
            className="multiple-sync-wrapper"
          >
            <Row>
              <Col>
                <FormGroup className="form-icon icon-end">
                  <Label for="exampleEmail">Date minimale</Label>
                  <Controller
                    control={syncControl}
                    name="since"
                    render={({field}) => (
                      <DatePicker
                        placeholderText="Date de début"
                        onChange={(date: any) => {
                          field.onChange(date);
                        }}
                        selected={
                          field.value
                            ? new Date((field as unknown as any).value)
                            : null
                        }
                        className="form-control form-secondary"
                        locale="fr"
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                  <span className="icon icon-secondary ">
                    <img src={CalenderIconBlue} alt="icon" />
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-icon icon-end">
                  <Label for="exampleEmail">Date maximale</Label>
                  <Controller
                    control={syncControl}
                    name="until"
                    render={({field}) => (
                      <DatePicker
                        placeholderText="Date de fin"
                        onChange={(date: any) => {
                          field.onChange(date);
                        }}
                        selected={
                          field.value
                            ? new Date((field as unknown as any).value)
                            : null
                        }
                        className="form-control form-secondary"
                        locale="fr"
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                  <span className="icon icon-secondary ">
                    <img src={CalenderIconBlue} alt="icon" />
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                color="secondary"
                type="submit"
                disabled={loadingState.status}
              >
                Synchroniser
              </Button>
              <Button
                outline
                color="secondary"
                type="button"
                disabled={loadingState.status}
                onClick={cancelEditDate}
              >
                Annuler
              </Button>
            </ModalFooter>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SynchroniseBridgeAccountModal;
