import React, {ReactNode, useEffect, useState} from "react";
import "./Reporting.scss";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {data} from "../../../constants";

import Select from "react-select";

import {BootyPagination} from "../../../components/table/pagination";
import {BsTrashFill} from "react-icons/bs";
import DataTable from "react-data-table-component";
import {
  RiCalendar2Line,
  RiIndeterminateCircleFill,
  RiSearchLine,
} from "react-icons/ri";
import {Company, IApplication, IPermission, User} from "../../../interfaces";
import {useSelector} from "react-redux";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import axios from "axios";
import config from "../../../config";
import {ErrorLogger} from "../../../util/errorLogger";

import moment from "moment";
import "moment/locale/fr";
import {OptionType, UserTypes} from "../../../util/context";
import {IoMdClose} from "react-icons/io";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";

import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import {ReactSVG} from "react-svg";
registerLocale("fr", fr);

const status = [
  {value: "encour", label: "En cours"},
  {value: "valider", label: "Valider"},
  {value: "rejeter", label: "rejeter"},
];
export interface ReportingProps {}

type FormValues = {
  company: OptionType | null;
  dateFrom: string | null;
  dateTo: string | null;
};

const {API_URL} = config[process.env.NODE_ENV];

const Reporting: React.FC<ReportingProps> = ({}) => {
  const [loading, setLoading] = useState(false);
  const [chosenCompanies, setChosenCompanies] = useState<
    Array<{label: string; value: string}>
  >([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [links, setLinks] = useState<Array<{url: string; key: string}>>([]);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const {clientsList} = useSelector(
    (state: {clientsList: Company[]}) => state.clientsList
  ) as unknown as {clientsList: Company[]};

  const {companiesList} = useSelector(
    (state: {companiesList: Company[]}) => state.companiesList
  ) as unknown as {companiesList: Company[]};

  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {user_id: string; company_id: string; token: string; role: string};

  useEffect(() => {
    resetState();
    setLinks([]);
  }, []);

  let api = useAxios();

  const fetchFile = async (type: string) => {
    try {
      if (!dateFrom || !dateTo || !chosenCompanies.length) {
        setErrorMessage({
          type: "fill-form",
          message: "Veuillez remplir tous les champs du formulaire",
        });
        return;
      }
      setLoading(true);
      setErrorMessage(null);

      let companiesPayload: Company[] = [];

      for (let comp of chosenCompanies) {
        let foundComp = companiesList.find((elt) => elt.id === comp.value);
        if (foundComp) {
          companiesPayload.push(foundComp);
        }
      }

      const {data} = await api.post(
        `/api/transaction/${
          type === "archived" ? "export" : "exportValidated"
        }`,
        {
          companies: companiesPayload,
          dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
          dateTo: moment(dateTo).format("YYYY-MM-DD"),
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (!data.response) {
        setLoading(false);
        setErrorMessage({
          type: "export-issue",
          message: (
            <p>
              Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
            </p>
          ),
        });
        return;
      }
      if (data.response.notProcessed.length > 0) {
        setErrorMessage({
          type: "export-issue",
          message: (
            <Row>
              <Col md={12}>
                Les entreprises suivantes n'ont pas de transaction valide à
                déclarer :{" "}
              </Col>
              <Col md={12}>
                <ul>
                  {data.response.notProcessed.map(
                    (
                      elt:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined,
                      key: React.Key | null | undefined
                    ) => (
                      <li key={key}>
                        <RiIndeterminateCircleFill /> {elt}
                      </li>
                    )
                  )}
                </ul>
              </Col>
            </Row>
          ),
        });
      }
      if (data.response.processed.length > 0) {
        setLinks(data.response.processed);
      }
      resetState();
      setChosenCompanies([]);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("exporting transaction", error);
      setLoading(false);
      setErrorMessage({
        type: "export-issue",
        message: (
          <p>
            Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
          </p>
        ),
      });
    }
  };

  const resetState = () => {
    setDateFrom(null);
    setDateTo(null);
    setChosenCompanies([]);
    // setLinks([]);
    setErrorMessage(null);
  };

  return (
    <div className="page page-reporting">
      <Card className="card-reporting">
        <Row>
          <Col>
            <FormGroup className="form-icon icon-end">
              <Label for="exampleEmail">Date minimale</Label>
              <DatePicker
                placeholderText="Date de début"
                onChange={(date: any) => {
                  setDateFrom(date);
                  setErrorMessage(null);
                }}
                selected={dateFrom ? new Date(dateFrom) : null}
                className="form-control form-secondary"
                locale="fr"
                dateFormat="dd/MM/yyyy"
              />
              <span className="icon icon-secondary ">
                {/* <img src={CalenderIconBlue} alt="icon" />{" "} */}
                {<ReactSVG src={CalenderIconBlue} />}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="form-icon icon-end">
              <Label for="exampleEmail">Date maximale</Label>
              <DatePicker
                placeholderText="Date de fin"
                onChange={(date: any) => {
                  setDateTo(date);
                  setErrorMessage(null);
                }}
                selected={dateTo ? new Date(dateTo) : null}
                className="form-control form-secondary"
                locale="fr"
                dateFormat="dd/MM/yyyy"
              />

              <span className="icon icon-secondary ">
                {<ReactSVG src={CalenderIconBlue} />}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="exampleEmail">Compte</Label>

              <Select
                options={
                  creds.role === UserTypes.Cabinet
                    ? clientsList.reduce(
                        (acc, curr) => [
                          ...acc,
                          {
                            label: curr.name || curr.email,
                            value: curr.id,
                          },
                        ],
                        [] as OptionType[]
                      )
                    : companiesList.reduce(
                        (acc, curr) => [
                          ...acc,
                          {
                            label: curr.name || curr.email,
                            value: curr.id,
                          },
                        ],
                        [] as OptionType[]
                      )
                }
                classNamePrefix="select"
                className="custom-select form-secondary"
                closeMenuOnSelect={true}
                value={null}
                onChange={(item) => {
                  const selectedComp = companiesList.find(
                    (elt) => elt.id === item?.value
                  );
                  setChosenCompanies((prevState) => {
                    const alreadyExists = (prevState as OptionType[]).findIndex(
                      (elt) => elt?.value === selectedComp?.id
                    );
                    if (alreadyExists !== -1) {
                      return prevState;
                    }
                    return [
                      ...prevState,
                      {
                        label: selectedComp?.name || selectedComp?.email,
                        value: selectedComp?.id,
                      },
                    ] as Array<{label: string; value: string}>;
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            {loading && (
              <div className="d-flex align-items-center">
                <Spinner color="info" type="border" size={"sm"}>
                  Loading...
                </Spinner>

                <p className="m-2">Création des fichiers</p>
              </div>
            )}
            {!loading && errorMessage?.type === "export-issue" && (
              <div className="d-flex align-items-center text-danger">
                {errorMessage?.message}
              </div>
            )}

            {!loading && errorMessage?.type === "fill-form" && (
              <div className="d-flex align-items-center text-danger">
                {errorMessage?.message}
              </div>
            )}

            {!loading && links.length > 0 && (
              <>
                {links.map((obj: any, index) => (
                  <div
                    className="comp-report d-flex align-items-center"
                    key={index}
                  >
                    <span>
                      Cliquez pour télécharger le reporting du {obj.company} :{" "}
                    </span>
                    <a
                      href={obj.urls.url}
                      target={"_blank"}
                      className="file-link"
                    >
                      &#10004;
                      {obj.urls.key}
                    </a>
                  </div>
                ))}
              </>
            )}
          </Col>

          <Col md={4} className={"reporting-companies-list"}>
            {chosenCompanies.map((comp, index) => (
              <span className="tags-item tags-secondary" key={index}>
                <span>{comp.label}</span>{" "}
                <IoMdClose
                  onClick={() => {
                    setChosenCompanies((prevState) => {
                      const companies = prevState.filter(
                        (elt) => elt.value !== comp.value
                      );
                      return companies;
                    });
                  }}
                />
              </span>
            ))}
          </Col>
        </Row>

        <div className="actions-footer">
          <Button
            color="secondary"
            outline
            onClick={() => {
              resetState();
              setLinks([]);
            }}
            disabled={loading}
          >
            Réinitialiser
          </Button>
          <Button
            color="secondary"
            type="button"
            disabled={loading}
            onClick={async () => {
              try {
                await fetchFile("archived");
              } catch (error) {}
            }}
          >
            Exporter les transactions archivées
          </Button>
          <Button
            color="primary"
            type="button"
            disabled={loading}
            onClick={async () => {
              try {
                await fetchFile("validated");
              } catch (error) {}
            }}
          >
            Exporter les transactions validées
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Reporting;
