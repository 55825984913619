import React, {ReactNode, useEffect, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import config from "../../config";
import {ErrorLogger} from "../../util/errorLogger";
import useAxios from "../../util/hooks/useAxios";
import {BridgeItemStatus, UserTypes, toastSettings} from "../../util/context";
import {
  CheckItem,
  RedirectUrls,
} from "../CompanyInfo/CompteSynchronise/CompteSynchronise";
import CalenderIconBlue from "../../assets/images/svg/calender-icon-blue.svg";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
import "moment/locale/fr";
import {deleteWhere} from "../../util/helpers/utilities";
import {persistor} from "../../store";
import {resetRoot} from "../../store/reducers/root/rootSlice";
import {resetApplications} from "../../store/reducers/applications/applicationsSlice";
import {resetCategories} from "../../store/reducers/categories/categoriesSlice";
import {resetClientsList} from "../../store/reducers/clients-list/clientsSlice";
import {resetCompaniesList} from "../../store/reducers/companies-list/companiesSlice";
import {resetPermissionsList} from "../../store/reducers/permissions-list/permissionsListSlice";
import {resetUser} from "../../store/reducers/user/userSlice";
import {resetNotifs} from "../../store/reducers/messages-notifs/messagesNotifsSlice";
import {
  resetUtil,
  setReloadTickets,
} from "../../store/reducers/utils/utilSlice";
import {toast} from "react-toastify";
import {IBridgeAccount} from "../../interfaces";
import "./style.scss";
import {AiFillCheckCircle, AiFillTool, AiFillWarning} from "react-icons/ai";
import {BsTrashFill} from "react-icons/bs";
import {deleteBridgeItem, fixBridgeItem} from "../../api/bridgeItem";
import {getStatusDescription} from "../../util/function";
import {ReactSVG} from "react-svg";
import GestionBridgeAccount from "./GestionBridgeAccount/GestionBridgeAccount";
registerLocale("fr", fr);

export interface AccountsChoicedProps {}

export type Accounts = {
  label: string;
  value: string;
  iban: string;
}[];

export type Ibans = {
  iban: string;
  account: string;
}[];

type GeneralFormValues = {
  accounts:
    | Accounts
    | {
        label: any;
        value: string;
        iban: string;
      }[]
    | null;
  since: string | null;
  until: string | null;
};

interface IBridgeItem {
  bank: any;
  bankId: number;
  bridge_accounts: IBridgeAccount[];
  company: any;
  companyId: string;
  company_id: string;
  id: number;
  lastSync: Date;
  status: number;
  status_code_info: string;
  bankInfo: Record<string, string>;
}

const AccountsChoice: React.FC<AccountsChoicedProps> = ({}) => {
  const [loading, setLoading] = useState({
    status: false,
    role: "",
  });

  const [item, setItem] = useState<string | null>();
  const [bridgeItem, setBridgeItem] = useState<IBridgeItem>({} as IBridgeItem);

  const [proValidationItems, setValidationItems] = useState<CheckItem[]>([]);
  const [otpItems, setOtpItems] = useState<CheckItem[]>([]);
  const [otherItems, setOtherItems] = useState<CheckItem[]>([]);
  const [proValidationUrl, setValidationUrl] = useState<string | null>();
  const [otpUrls, setOtpUrls] = useState<RedirectUrls[]>([]);
  const [stopSyncModal, setStopSyncModal] = useState<boolean>(false);

  const [accounts, setAccounts] = useState<IBridgeAccount[]>([]);
  const [ibans, setIbans] = useState<IBridgeAccount[]>([] as IBridgeAccount[]);
  let navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const [multiSyncSuccessMessage, setMultiSyncSuccessMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const [searchParams] = useSearchParams();
  const clientId = searchParams.get("clientId");
  const itemId = searchParams.get("item");
  const companiesList = useSelector(
    (state: {root: object; user: Record<string, unknown>}) =>
      state.user.userCompanies
  ) as Array<Record<string, string>>;

  const dispatch = useDispatch();

  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    pack: string;
    application_id: string;
  };

  const leave = async () => {
    try {
      await deleteWhere(api, creds.token, "CountNotif", {
        company_id: creds.company_id,
      });

      await api.get(`/api/auth/logout`, {
        headers: {
          "x-access-token": creds.token,
        },
      });
      persistor.purge();
      dispatch(resetRoot());
      dispatch(resetApplications());
      dispatch(resetCategories());
      dispatch(resetClientsList());
      dispatch(resetCompaniesList());
      dispatch(resetPermissionsList());
      dispatch(resetUser());
      dispatch(resetNotifs());
      dispatch(resetUtil());
      dispatch(setReloadTickets(false));
      toast.dismiss();
      navigate("/login");
    } catch (error: any) {
      ErrorLogger("logout", error);
    }
  };

  const gestionAccounts = async () => {
    if (itemId === "undefined") {
      navigate("/");
      return;
    }
    setItem(itemId);

    try {
      const {data} = await api.post(
        `/api/v2/BridgeItem/All`,
        {
          where: {
            id: itemId,
            clientId,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (!data?.data?.length) {
        return;
      }
      setBridgeItem(data.data[0]);

      const list = data?.data[0]?.bridge_accounts || [];

      setAccounts(list);
      setIbans(
        list.map((elt: IBridgeAccount) => ({
          ...elt,
          iban: elt.iban || "",
        }))
      );
    } catch (error: any) {
      ErrorLogger("getting indemnities", error);
    }
  };

  useEffect(() => {
    if (clientId) {
      gestionAccounts();
    }
  }, [clientId]);

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<GeneralFormValues>({});

  let api = useAxios();

  const deleteItem = async () => {
    setLoading({
      status: true,
      role: "delete",
    });
    try {
      await deleteBridgeItem({
        api,
        itemId: Number(itemId),
        clientId: clientId!,
      });
      navigate("/");
    } catch (error: any) {
      ErrorLogger("delete bridge item", error);
    }
    setLoading({
      status: false,
      role: "",
    });
  };

  const fixItem = async () => {
    setLoading({
      status: true,
      role: "update",
    });
    try {
      const {data} = await fixBridgeItem({
        api,
        itemId: Number(itemId),
        clientId: clientId!,
        status: bridgeItem.status,
        from: creds.application_id,
      });
      if (!!data?.url) {
        window.location = data?.url;
      }
    } catch (error: any) {
      console.log("error", error);
      ErrorLogger("delete bridge item", error);
    } finally {
      setLoading({
        status: false,
        role: "",
      });
    }
  };

  return (
    <>
      <div className="page-infos-societes">
        <div>
          <Card className="card-Table table-primary">
            <div className="section-information-societe">
              {bridgeItem?.status_code_info ? (
                <section>
                  <h3>Status de la synchronisation</h3>
                  <ul className=" bridgeItem-container">
                    <li className="bridgeItem-item">
                      <h5>Banque</h5>
                      <div className="flex-wrap" style={{height: "100%"}}>
                        {bridgeItem.bankInfo &&
                          bridgeItem.bankInfo.logo_url && (
                            <img
                              src={bridgeItem.bankInfo.logo_url}
                              className="bankImg-item"
                            />
                          )}
                        {bridgeItem.bankInfo && bridgeItem.bankInfo.name && (
                          <p style={{margin: 0, minWidth: "30px"}}>
                            {bridgeItem.bankInfo.name}
                          </p>
                        )}
                      </div>
                    </li>
                    <li className="bridgeItem-item">
                      <h5>Status</h5>
                      <div className="flex-wrap" style={{height: "100%"}}>
                        {bridgeItem.status === 0 ? (
                          <AiFillCheckCircle
                            color="green"
                            width={"25px"}
                            height={"25px"}
                          />
                        ) : (
                          <AiFillWarning
                            color="orange"
                            style={{width: "25px", height: "25px"}}
                          />
                        )}
                      </div>
                    </li>
                    <li className="bridgeItem-item">
                      <h5>Description</h5>
                      <div className="flex-wrap" style={{height: "100%"}}>
                        {bridgeItem.status === 0 ? (
                          "Valider"
                        ) : (
                          <>
                            {getStatusDescription(
                              bridgeItem?.status?.toString()
                            )}
                          </>
                        )}
                      </div>
                    </li>
                    <li className="bridgeItem-item">
                      <h5>Action</h5>
                      <div className="flex-wrap" style={{height: "100%"}}>
                        {bridgeItem.status !== 0 ? (
                          <button
                            className="btn btn-blue"
                            title="fixer le statut de devis"
                            disabled={loading.status}
                            onClick={fixItem}
                          >
                            {loading.status && loading.role === "update" ? (
                              <Spinner color="light" type="border" size={"sm"}>
                                Loading...
                              </Spinner>
                            ) : (
                              <AiFillTool />
                            )}
                          </button>
                        ) : null}

                        <button
                          className="btn btn-red"
                          title="Supprimer le statut de devis"
                          onClick={deleteItem}
                          disabled={loading.status}
                        >
                          {loading.status && loading.role === "delete" ? (
                            <Spinner color="red" type="border" size={"sm"}>
                              Loading...
                            </Spinner>
                          ) : (
                            <BsTrashFill />
                          )}
                        </button>
                      </div>
                    </li>
                  </ul>
                </section>
              ) : null}
              {/* <div className="section-information-societe">
                <h3>Comptes à synchroniser</h3>
                <form
                  onSubmit={handleSubmit(synchronize)}
                  style={{ textAlign: "start" }}
                >
                  <Row>
                    <Col md={4} sm={12}>
                      <FormGroup className="form-icon icon-end">
                        <Label for="since">Date minimale</Label>
                        <Controller
                          control={control}
                          name="since"
                          render={({ field }) => (
                            <DatePicker
                              placeholderText="Date de début"
                              onChange={(date: any) => {
                                field.onChange(date);
                                setErrorMessage(null);
                              }}
                              selected={
                                field.value
                                  ? new Date((field as unknown as any).value)
                                  : null
                              }
                              className="form-control form-secondary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              disabled={bridgeItem.status === 0 ? false : true}
                            />
                          )}
                        />
                        <span className="icon icon-secondary ">
                          {<ReactSVG src={CalenderIconBlue} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <FormGroup className="form-icon icon-end">
                        <Label for="until">Date maximale</Label>
                        <Controller
                          control={control}
                          name="until"
                          render={({ field }) => (
                            <DatePicker
                              placeholderText="Date de fin"
                              onChange={(date: any) => {
                                field.onChange(date);
                                setErrorMessage(null);
                              }}
                              selected={
                                field.value
                                  ? new Date((field as unknown as any).value)
                                  : null
                              }
                              className="form-control form-secondary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              disabled={bridgeItem.status === 0 ? false : true}
                            />
                          )}
                        />
                        <span className="icon icon-secondary ">
                          {<ReactSVG src={CalenderIconBlue} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <FormGroup>
                        <Label for="accounts">
                          Veuillez choisir les comptes à synchroniser
                        </Label>
                        <Controller
                          name="accounts"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={accounts.map((elt) => ({
                                label: (
                                  <span>
                                    {elt.name}
                                    {elt.iban ? (
                                      <>
                                        <br />({`iban: ${elt.iban}`})
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                ),
                                value: String(elt.id),
                                iban: elt.iban ?? "",
                              }))}
                              closeMenuOnSelect={true}
                              isMulti
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                              isDisabled={
                                bridgeItem.status === 0 ? false : true
                              }
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {errorMessage?.type === "invalid_sync_form" && (
                    <span className="d-flex justify-content-center text-danger mt-2">
                      {errorMessage?.message}
                    </span>
                  )}
                  {loading.status && loading.role === "async" && (
                    <span className="d-flex justify-content-center text-info mt-2">
                      Synchronisation en cours, veuillez patienter.
                    </span>
                  )}
                  {errorMessage?.type === "multi_sync_error" && (
                    <span className="d-flex justify-content-center text-danger mt-2">
                      {errorMessage?.message}
                    </span>
                  )}
                  {multiSyncSuccessMessage?.type === "multi_sync_success" && (
                    <span className="d-flex justify-content-center text-success mt-2">
                      {multiSyncSuccessMessage?.message}
                    </span>
                  )}
                  <div
                    className="py-3 bottom-actions"
                    style={{ textAlign: "end" }}
                  >
                    <Button
                      color="secondary"
                      style={{ paddingInline: "30px" }}
                      type="submit"
                      disabled={bridgeItem.status !== 0 || loading.status}
                    >
                      {loading.status && loading.role === "async" ? (
                        <Spinner color="light" type="border" size={"sm"}>
                          Loading...
                        </Spinner>
                      ) : (
                        "Valider"
                      )}
                    </Button>
                  </div>
                </form>
              </div> */}
              {ibans.length > 0 && (
                <div
                  className="section-information-societe"
                  style={{textAlign: "start", paddingTop: 0}}
                >
                  <h3>Gestion des comptes</h3>
                  <GestionBridgeAccount
                    ibans={ibans}
                    getBridgeAccounts={gestionAccounts}
                    clientId={clientId!}
                    companiesList={companiesList}
                  />
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
      <div className="openbtn text-center">
        {/* stop sync account */}
        <Modal
          className="modal-secondary modal-dialog-centered"
          isOpen={stopSyncModal}
          toggle={() => {
            setLoading({status: false, role: ""});
            setStopSyncModal(false);
            setOtpItems([]);
            setOtherItems([]);
            setValidationItems([]);
          }}
        >
          <ModalHeader
            toggle={() => {
              setLoading({status: false, role: ""});
              setStopSyncModal(false);
              setOtpItems([]);
              setOtherItems([]);
              setValidationItems([]);
            }}
          >
            Vérification du compte bancaire
          </ModalHeader>
          <ModalBody>
            <div className="text-center">
              <h5>
                Nous ne pouvons pas procéder à la synchronisation de ces comptes
                en raison de :{" "}
              </h5>
            </div>
            <div className="text-left my-4">
              {otpItems.length > 0 && (
                <ul>
                  {otpItems.map((otpItem, index) => {
                    return (
                      <li key={index}>
                        Un ou plusieurs de vos comptes nécessitent une{" "}
                        <strong>authentification client forte</strong> , cliquez{" "}
                        <a
                          href={
                            otpUrls.find((elt) => elt.item === otpItem.id)
                              ?.url || ""
                          }
                          // target="_blank"
                          className="text-primary"
                        >
                          <strong>
                            <u>ici</u>
                          </strong>
                        </a>{" "}
                        pour vérifier ces comptes
                      </li>
                    );
                  })}
                </ul>
              )}
              {proValidationItems.length > 0 && (
                <ul>
                  {proValidationItems.map((proValidationItem, index) => {
                    return (
                      <li key={index}>
                        Un ou plusieurs de vos comptes professionnels
                        nécessitent <strong>une validation</strong> , cliquez{" "}
                        <a
                          className="text-primary"
                          href={proValidationUrl || ""}
                          // target="_blank"
                        >
                          <strong>
                            <u>ici</u>
                          </strong>
                        </a>{" "}
                        pour vérifier ces comptes
                      </li>
                    );
                  })}
                </ul>
              )}
              {otherItems.length > 0 && (
                <ul>
                  {otherItems.map((otherItem, index) => {
                    return (
                      <li key={index}>
                        Un ou plusieurs de vos comptes ne peuvent pas être
                        traités, veuillez contacter{" "}
                        <strong>
                          l'administrateur pour résoudre le problème
                        </strong>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="left my-4">
              <strong>
                <span className="text-danger">NB :</span> Vous{" "}
                <strong>ne pouvez pas</strong> procéder à la synchronisation de
                ces comptes et à l'importation de vos transactions tant que vous{" "}
                <strong>ne les avez pas validés.</strong>
              </strong>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default AccountsChoice;
